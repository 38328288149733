import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const PdfViewer = ({ pdfUrl, textData, onClose }) => {
  const [isDocumentLoaded, setDocumentLoaded] = useState(false);
  const handleDocumentLoad = () => setDocumentLoaded(true);

  const removeQuotes = textData.replaceAll('"', "").trim();
  const splitArr = removeQuotes?.split(/\s+/);

  const trimmedArray = [];
  const trimCount = 5;
  for (let i = 0; i < splitArr.length; i += trimCount) {
    trimmedArray.push(splitArr.slice(i, i + trimCount).join(" "));
  }

  if (
    trimmedArray.length > 1 &&
    trimmedArray[trimmedArray.length - 1].split(" ").length === 1
  ) {
    trimmedArray[trimmedArray.length - 2] += ` ${trimmedArray.pop()}`;
  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      searchPlugin: {
        keyword: trimmedArray,
        onHighlightKeyword: (props) => {
          props.highlightEle.style.backgroundColor = "rgba(255,240, 0, .2)";
          // props.highlightEle.style.margin = "20px 0";
        },
      },
    },
  });

  const { toolbarPluginInstance } = defaultLayoutPluginInstance;
  const { searchPluginInstance } = toolbarPluginInstance;
  const { highlight } = searchPluginInstance;

  useEffect(() => {
    if (isDocumentLoaded) {
      highlight(trimmedArray);
    }
  }, [isDocumentLoaded]);

  if (!textData || textData.trim().length === 0) {
    return (
      <div className="pdf-viewer">
        <div className="pdf-header">
          <button onClick={onClose}>Close</button>
        </div>
        <p className="pdf-message-no-data">
          No search results found for your query
        </p>
      </div>
    );
  }

  return (
    <div className="pdf-viewer">
      <div className="pdf-header">
        <button onClick={onClose}>Close</button>
      </div>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
        <div
          style={{
            height: "100vh",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "20px",
            paddingBottom: "30px",
          }}
        >
          <Viewer fileUrl={pdfUrl} plugins={[defaultLayoutPluginInstance]} onDocumentLoad={handleDocumentLoad}  />
          {/* <Viewer
            fileUrl={pdfUrl}
            plugins={[searchPluginInstance]}
            onDocumentLoad={handleDocumentLoad}
          /> */}
        </div>
      </Worker>
    </div>
  );
};

export default PdfViewer;
